/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useCallback } from 'react';

/**
 * Creates a stable callback pointing to the **latest
 * render** [callback] provided to this function, making it stable
 * without specifying deps.
 *
 * Note: Don't use this for render callbacks, this will cause
 * renders to not update properly when state changes. For render
 * callbacks, you usually should be using useMemo instead.
 */

export const useRefCallback = <T extends (...args: any[]) => any>(
  callback: T
) => {
  const ref = useRef(callback);
  ref.current = callback;

  return useCallback((...args: any) => {
    return ref.current(...args);
  }, []) as unknown as T; // this T cast will make sure we have correct args for T
};
