import { getRestHeaders } from '@src/utils/network';
import getConfig from 'next/config';

const { API_HOST } = getConfig().publicRuntimeConfig;

type SignupTracks = {
  signup_source?: string;
  signup_source_qualifier?: string;
  initial_referring_domain?: string;
  referrer_code?: string[];
  referral_code?: string;
  gclid?: string;
  gbraid?: string;
  wbraid?: string;
};

type SignupUserData = {
  first_name: string;
  preferred_name: string;
  last_name: string;
  role_id: string | null;
  nurse_type: string | null;
};

type FBSignupParams = SignupTracks &
  SignupUserData & {
    signup?: boolean;
    email?: string;
    facebook_user_id: string;
    facebook_access_token: string;
  };

type AppleSignUpParams = SignupTracks &
  SignupUserData & {
    signup: boolean | undefined;
    identity_token: string;
  };

type GoogleSignUpParams = SignupTracks &
  SignupUserData & {
    signup?: boolean;
    google_access_token: string;
  };

type OpenIdProfile = {
  email?: string;
  given_name?: string;
  family_name?: string;
  sub: string;
};

export type GetOpenIdResult = {
  profile: OpenIdProfile;
  access_token: string;
};

type YahooSignUpParams = SignupTracks &
  SignupUserData & {
    signup?: boolean;
    // Optionality of these might be wrong.
    profile?: OpenIdProfile;
    access_token?: string;
    code?: string;
  };

type YahooGetOpenIdProfileParams = {
  code?: string;
};

export type CommonErrorResult = {
  // Usually login related
  error?: string;
  // Only SSO related
  sso_error?: string;
};

export type AuthSuccessResult = {
  uuid: string;
  email: string;
  first_name: string;
  last_name: string;
  auth_token: string;
  intercom_user_hash: string;
  membership_type: string;
};

type SSOAuthAPIResult = CommonErrorResult | AuthSuccessResult;

export const isSSOError = (
  result: SSOAuthAPIResult
): result is CommonErrorResult => {
  return 'error' in result || 'sso_error' in result;
};

export function signUpFb(body: FBSignupParams): Promise<SSOAuthAPIResult> {
  return fetch(`${API_HOST}/facebook_auth`, {
    method: 'POST',
    headers: getRestHeaders(),
    body: JSON.stringify(body),
  }).then(response => response.json());
}

export function signUpApple(
  body: AppleSignUpParams
): Promise<SSOAuthAPIResult> {
  return fetch(`${API_HOST}/mobile/apple_auth`, {
    method: 'POST',
    headers: getRestHeaders(),
    body: JSON.stringify(body),
  }).then(response => response.json());
}

export function signUpGoogle(
  body: GoogleSignUpParams
): Promise<SSOAuthAPIResult> {
  return fetch(`${API_HOST}/google_auth`, {
    method: 'POST',
    headers: getRestHeaders(),
    body: JSON.stringify(body),
  }).then(response => response.json());
}

export function signUpYahoo(
  body: YahooSignUpParams
): Promise<SSOAuthAPIResult> {
  return fetch(`${API_HOST}/yahoo_auth`, {
    method: 'POST',
    headers: getRestHeaders(),
    body: JSON.stringify(body),
  }).then(response => response.json());
}

export function getOpenIdProfileYahoo(
  queryParams: YahooGetOpenIdProfileParams
): Promise<GetOpenIdResult> {
  return fetch(`${API_HOST}/yahoo_profile?code=${queryParams.code}`, {
    method: 'GET',
    headers: getRestHeaders(),
  }).then(response => response.json());
}

// Response structure from https://github.com/trusted/trusted-api/blob/6c923da/app/controllers/files_controller.rb
type UploadedFileResponse = {
  uuid: string;
  filename: string;
  download_url: string;
  view_url: string;
  preview_url: string;
  created_at: string;
  content_type: string;
};

type UploadedFileError = {
  errors: string;
};

type FileUploadResponse = { files: UploadedFileResponse[] } | UploadedFileError;

/**
 * @deprecated Use dedicated GraphQL mutations
 */
export const uploadFiles = async (files: File[]) => {
  const formData = new FormData();

  const headers = getRestHeaders();
  delete headers['Content-Type']; // Needs to be set by fetch

  for (let i = 0; i < files.length; i++) {
    formData.append('files[]', files[i]);
  }

  const res = await fetch(`${API_HOST}/files`, {
    method: 'POST',
    headers,
    body: formData,
  });

  return res.json() as Promise<FileUploadResponse>;
};
